<template>
  <section>
    <slot v-if="sharedSiteTokenLoaded"></slot>

    <SiteContactAccessDialog
      :showSiteContactAccessDialog="showSiteContactAccessDialog"
    />
  </section>
</template>

<script>
import SiteContactAccessDialog from "@/components/shared-site/SiteContactAccessDialog";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SharedSiteLayout",
  components: {
    SiteContactAccessDialog,
  },
  data() {
    return {
      showSiteContactAccessDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "sharedSiteTokenLoaded",
      "sharedSiteSignedOut",
      "forbiddenError",
    ]),
  },
  methods: {
    ...mapMutations([
      "setSharedSiteTokenLoaded",
      "setSharedSiteSignedOut",
      "setForbiddenError",
    ]),
    async checkSiteToken() {
      try {
        if (this.$route.query.contact) {
          localStorage.setItem("shared-site-token", this.$route.query.contact);
          this.setSharedSiteTokenLoaded(true);
        } else if (localStorage.getItem("shared-site-token")) {
          this.setSharedSiteTokenLoaded(true);
        } else {
          this.setSharedSiteSignedOut(true);
        }
      } catch (error) {
        console.log(error);
        this.setSharedSiteSignedOut(true);
      }
    },
  },
  beforeMount() {
    this.checkSiteToken();
  },
  watch: {
    sharedSiteSignedOut: {
      immediate: true,
      handler(val) {
        this.showSiteContactAccessDialog = val;
      },
    },
    forbiddenError(val) {
      if (val) {
        this.showSiteContactAccessDialog = true;
        this.setForbiddenError(false);
      }
    },
  },
};
</script>
