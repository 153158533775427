var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.sharedSiteTokenLoaded ? _vm._t("default") : _vm._e(),
      _c("SiteContactAccessDialog", {
        attrs: { showSiteContactAccessDialog: _vm.showSiteContactAccessDialog }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }