var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showSiteContactAccessDialog,
        persistent: "",
        "max-width": "300px"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [_c("div", [_vm._v("Site Contact Access")])]
          ),
          _c("v-card-text", { staticClass: "pt-5 pb-2 px-6" }, [
            _c("div", { staticClass: "d-flex justify-center align-center" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/UtiliSyncDocsLogo.svg"),
                  height: "75px",
                  width: "auto",
                  id: "logo"
                }
              })
            ]),
            _c(
              "div",
              [
                _c("validation-observer", { ref: "emailForm" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _c(
                        "section",
                        [
                          _c("validation-provider", {
                            ref: "emailInput",
                            attrs: { name: "Email", rules: "required|email" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  var valid = ref.valid
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        "prepend-icon": _vm.mdiAccount,
                                        label: "Email*",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "#3F51B5",
                                        name: "email"
                                      },
                                      model: {
                                        value: _vm.email,
                                        callback: function($$v) {
                                          _vm.email = $$v
                                        },
                                        expression: "email"
                                      }
                                    }),
                                    valid
                                      ? [
                                          typeof _vm.siteCount === "undefined"
                                            ? _c(
                                                "p",
                                                { staticClass: "caption pl-8" },
                                                [
                                                  _vm._v(
                                                    " Enter your email to access the site "
                                                  )
                                                ]
                                              )
                                            : _vm.siteCount === 0
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass: "caption pl-8",
                                                  staticStyle: {
                                                    color: "#ff5252"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " You do not have access to this site. Try another email, or request access from the site owner. "
                                                  )
                                                ]
                                              )
                                            : _vm.siteCount > 0
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass: "caption pl-8",
                                                  staticStyle: {
                                                    color: "#4caf50"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " You are a verified contact for this site. See your email for a link to access this site. "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "pt-5 pb-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: !_vm.buttonDisabled,
                                color: "#3F51B5",
                                width: "100%",
                                type: "submit",
                                disabled: _vm.buttonDisabled
                              }
                            },
                            [_vm._v(" Access Site ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }