import { axiosWithJwtAuth } from "@/plugins/axios";
import { mapGetters } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  beforeDestroy() {
    const s = document.getElementById("usetifulScript");
    s.remove();
  },
  methods: {
    async loadUsetiful() {
      if (this.$route.query.siteId) {
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/users/one/${this.$route.query.siteId}`
        );
        const currentUser = results;
        const {
          email,
          user_id: userId,
          f_name: fName,
          l_name: lName,
          is_gis_admin: isGisAdmin,
          is_locate_request_user: isLocatePowerUser,
          is_power_user: isPowerUser,
          is_sys_admin: isSysAdmin,
          is_view_only_user: isViewOnlyUser,
          username,
        } = currentUser;
        window.usetifulTags = {
          email,
          userId: userId,
          f_name: fName,
          l_name: lName,
          is_gis_admin: JSON.stringify(isGisAdmin),
          is_locate_request_user: JSON.stringify(isLocatePowerUser),
          is_power_user: JSON.stringify(isPowerUser),
          is_sys_admin: JSON.stringify(isSysAdmin),
          is_view_only_user: JSON.stringify(isViewOnlyUser),
          username,
        };
      } else {
        window.usetifulTags = {};
      }

      (function (w, d, s) {
        var a = d.getElementsByTagName("head")[0];
        var r = d.createElement("script");
        r.async = 1;
        r.src = s;
        r.setAttribute("id", "usetifulScript");
        r.dataset.token = "4fd9a02f6e5b519a885815a6eea2c10b";
        a.appendChild(r);
      })(window, document, "https://www.usetiful.com/dist/usetiful.js");
    },
  },
  computed: {
    ...mapGetters(["sharedSiteTokenLoaded"]),
  },
  watch: {
    sharedSiteTokenLoaded: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.loadUsetiful();
        }
      },
    },
  },
};
