<template>
  <v-dialog :value="showSiteContactAccessDialog" persistent max-width="300px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Site Contact Access</div>
      </v-toolbar>

      <v-card-text class="pt-5 pb-2 px-6">
        <div class="d-flex justify-center align-center">
          <img
            src="@/assets/UtiliSyncDocsLogo.svg"
            height="75px"
            width="auto"
            id="logo"
          />
        </div>

        <div>
          <validation-observer ref="emailForm">
            <form @submit.prevent="submit()">
              <section>
                <validation-provider
                  ref="emailInput"
                  v-slot="{ errors, valid }"
                  name="Email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="email"
                    :prepend-icon="mdiAccount"
                    label="Email*"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="#3F51B5"
                    name="email"
                  />
                  <template v-if="valid">
                    <p
                      class="caption pl-8"
                      v-if="typeof siteCount === 'undefined'"
                    >
                      Enter your email to access the site
                    </p>
                    <p
                      class="caption pl-8"
                      v-else-if="siteCount === 0"
                      style="color: #ff5252"
                    >
                      You do not have access to this site. Try another email, or
                      request access from the site owner.
                    </p>
                    <p
                      class="caption pl-8"
                      v-else-if="siteCount > 0"
                      style="color: #4caf50"
                    >
                      You are a verified contact for this site. See your email
                      for a link to access this site.
                    </p>
                  </template>
                </validation-provider>
              </section>

              <section class="pt-5 pb-3">
                <v-btn
                  :dark="!buttonDisabled"
                  color="#3F51B5"
                  width="100%"
                  type="submit"
                  :disabled="buttonDisabled"
                >
                  Access Site
                </v-btn>
              </section>
            </form>
          </validation-observer>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import { axiosWithNoAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SiteContactAccessDialog",
  props: {
    showSiteContactAccessDialog: Boolean,
  },
  data() {
    return {
      mdiAccount,
      email: "",
      siteCount: undefined,
      buttonDisabled: false,
    };
  },
  methods: {
    async submit() {
      const { email } = this;
      const {
        data: { results: siteCount },
      } = await axiosWithNoAuth.post(
        `${APIURL}/shared_site/auth/get_num_shared_site_contacts`,
        {
          email,
        }
      );
      this.siteCount = siteCount;
      if (siteCount > 0) {
        await axiosWithNoAuth.post(
          `${APIURL}/shared_site_contacts/resend_link`,
          {
            email,
          }
        );
        this.buttonDisabled = true;
      }
    },
  },
};
</script>
